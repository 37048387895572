import { getAppointmentsHistoryWithTimeslots, getUpcomingData } from "./../../utils/utils";
import {
    GET_PRODUCT_ORDERS_BEGIN,
    GET_PRODUCT_ORDERS_SUCCESS,
    GET_PRODUCT_ORDERS_FAILED,
    DASHBOARD_SEARCH_DATA,
    SET_DATA_TO_RENDER,
    SET_SEARCH_KEYS,
    SET_COLUMN_PER_PAGE,
    SET_ORDER,
    SET_STATUS,
    POST_CONFIRM_HOME_TEST_BEGIN,
    POST_CONFIRM_HOME_TEST_SUCCESS,
    POST_CONFIRM_HOME_TEST_FAILED,
    SHOW_QRCODE_POP_UP,
    RESET_ACTION,
    CLEAR_TABLE_FILTER,
    UPDATE_CONSULTATION_STATE_RECORD,
    GET_PRODUCT_BARCODE_BEGIN,
    GET_PRODUCT_BARCODE_SUCCESS,
    GET_PRODUCT_BARCODE_FAILED,
    POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY,
    POST_CONFIRM_HOME_TEST_FAILED_MANUALLY,
    POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY,
    GET_SICK_LEAVE_SUCCESS,
    GET_PRESCRIPTION_SUCCESS,
    // GET_ANAMNESIS_BEGIN,
    GET_ANAMNESIS_FAILED,
    GET_ANAMNESIS_SUCCESS,
    POST_ANAMNESIS_BEGIN,
    POST_ANAMNESIS_FAILED,
    POST_ANAMNESIS_SUCCESS,
    GET_ANAMNESIS_HISTORY_SUCCESS,
    SET_ANAMNESIS_STATE,
    // GET_EXAMINATIONS_BEGIN,
    GET_EXAMINATIONS_FAILED,
    GET_EXAMINATIONS_SUCCESS,
    GET_VITAL_SIGNS_BEGIN,
    GET_VITAL_SIGNS_FAILED,
    GET_VITAL_SIGNS_SUCCESS,
    GET_CUSTOMER_ATTACHMENTS_BEGIN,
    GET_CUSTOMER_ATTACHMENTS_FAILED,
    GET_CUSTOMER_ATTACHMENTS_SUCCESS
    // HANDLE_BARCODE
} from "actions/dashboard/dashboard";
import { produce } from "immer";
import { filterByProductGroup } from "utils/utils";
import Fuse from "fuse.js";
import shortUUID from "short-uuid";

const initialState: any = {
    data: [],
    filteredData: [],
    allOrders: [],
    orderedProducts: [],
    onSiteTests: [],
    homeTests: [],
    diagnosticsData: [],
    consultationsData: [],
    testResultsData: [],
    onlineConsultationResult: [],
    anamnesisResult: [],
    anamnesisState: [],
    anamnesisHistory: [],
    examinations: [],
    vitalSignsResult: [],
    sickLeave: [],
    prescription: [],
    barcodeData: null,
    showQrCodePopup: false,
    columndPerPage: 10,
    order: "",
    status: "",
    fuseSearch: new Fuse([], { keys: [], threshold: 0 }),
    searchKeyword: "",
    fuseOptions: {
        keys: [],
        threshold: 0
    },
    action: {
        isLoading: false,
        error: null,
        message: "",
        isSuccess: false,
        actionName: null
    },
    uploadedFiles: []
};

const setId = (data) => {
    return data.map((el) => {
        return {
            ...el,
            uid: shortUUID.generate()
        };
    });
};

const sortData = (filteredData: any, order: string) => {
    if (order === "asc") {
        return filteredData.slice().sort((a, b) => Date.parse(b.booking_date) - Date.parse(a.booking_date));
        // .concat(dataWithoutDate);
    }
    if (order === "desc") {
        return filteredData.slice().sort((a, b) => Date.parse(a.booking_date) - Date.parse(b.booking_date));
    }
    // .concat(dataWithoutDate);
};

const filterByStatus = (data: any, currentStatus: string) => {
    const getStatus = (status: string) => {
        switch (status) {
            case "A":
                return "Open";
            case "T":
                return "Pending";
            case "R":
                return "Closed";
            default:
                break;
        }
    };
    return data.filter((el) => getStatus(el.record_state) === currentStatus);
};

const dashboardReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            // case GET_EXAMINATIONS_BEGIN:
            case GET_PRODUCT_BARCODE_BEGIN:
            case GET_PRODUCT_ORDERS_BEGIN:
                draft.action.isLoading = true;
                draft.action.isSuccess = false;
                draft.action.error = null;
                draft.action.actionName = action.type;
                break;
            case GET_EXAMINATIONS_FAILED:
            case GET_VITAL_SIGNS_FAILED:
            case GET_PRODUCT_BARCODE_FAILED:
            case GET_PRODUCT_ORDERS_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                break;
            case GET_SICK_LEAVE_SUCCESS:
                draft.sickLeave = action.payload;
                break
            case GET_PRESCRIPTION_SUCCESS:
                draft.prescription = action.payload;
                break
            case GET_PRODUCT_ORDERS_SUCCESS: {
                const onSiteData = setId(
                    action.payload.filter((item) => !(["H", "C", "F"].includes(item.product_group) || !item.start))
                );
                const consultationsData = setId(filterByProductGroup(action.payload, ["C", "F"]));
                const upcomingHomeTestData = setId(
                    action.payload.filter((item: any) => item.product_group === "H" && item.item_record_state === "A")
                );
                const waitingResultHomeTestData = setId(
                    action.payload.filter((item: any) => item.product_group === "H" && item.item_record_state === "T")
                );
                const walkInsData = setId(
                    action.payload.filter(
                        (item: any) => item.product_group !== "H" && !item.start && item.item_record_state === "A"
                    )
                );
                // const appointmentHistoryData = setId(getAppointmentsHistoryWithTimeslots(action.payload));

                draft.allOrders = setId(action.payload);
                draft.upcomingAppointments = setId(
                    getUpcomingData([
                        ...walkInsData,
                        ...upcomingHomeTestData,
                        ...onSiteData.filter((e) => e.item_record_state === "A" && e.start),
                        ...consultationsData.filter(
                            (e) => e.item_record_state !== "R" && e.start && e.eyeson_room_status !== "C"
                        ),
                        ...waitingResultHomeTestData
                    ], 'upcomingsAppointment')
                );
                draft.dashboardUpcomings = setId(
                    getUpcomingData([
                        ...upcomingHomeTestData,
                        ...onSiteData.filter((e) => e.item_record_state === "A"),
                        ...consultationsData.filter((e) => e.item_record_state !== "R" && e.eyeson_room_status !== "C"),
                        ...walkInsData,
                        ...waitingResultHomeTestData
                    ], 'dashboardUpcomings')
                );
                draft.appointmentHistory = setId(getAppointmentsHistoryWithTimeslots(action.payload));
                draft.orderedProducts = setId(
                    action.payload.filter((item) => {
                        if (item.product_group === "D" || item.product_group === "C" || item.product_group === "F") {
                            return false;
                        }
                        return true;
                    })
                );
                // draft.onSiteTests = onSiteData;
                // draft.homeTests = homeTestData;
                draft.consultationsData = consultationsData;
                draft.diagnosticsData = setId(action.payload.filter((e) => e.diagnose_result || e.test_result === "V"));
                draft.testResultsData = action.payload.filter((e) => e.diagnose_result || e.test_result === "V");
                draft.onlineConsultationResult = action.payload.filter((e) => e["online_consultation"]);
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = action.type;
                break;
            }
            case GET_PRODUCT_BARCODE_SUCCESS:
                draft.barcodeData = action.payload;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = action.type;
                break;
            case SET_DATA_TO_RENDER:
                draft.data = action.payload;
                draft.filteredData = action.payload;
                draft.fuseSearch = new Fuse(action.payload, state.fuseOptions);
                break;
            case SET_SEARCH_KEYS:
                draft.fuseOptions.keys = action.payload;
                break;
            case DASHBOARD_SEARCH_DATA:
                draft.filteredData =
                    action.keyword !== ""
                        ? state.fuseSearch.search(action.keyword).map((el) => el.item)
                        : [...state.data];
                draft.searchKeyword = action.keyword;
                break;
            case SET_COLUMN_PER_PAGE:
                draft.columndPerPage = action.payload;
                break;
            case SET_ORDER:
                draft.order = action.payload;
                draft.filteredData = sortData(state.filteredData, action.payload);
                break;
            case SET_STATUS:
                draft.status = action.payload;
                draft.filteredData = filterByStatus(state.data, action.payload);
                break;
            // case GET_ANAMNESIS_BEGIN:
            case GET_CUSTOMER_ATTACHMENTS_BEGIN:
            case GET_VITAL_SIGNS_BEGIN:
            case POST_ANAMNESIS_BEGIN:
            case POST_CONFIRM_HOME_TEST_BEGIN:
            case POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY:
                draft.action.isLoading = true;
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                break;
            case POST_CONFIRM_HOME_TEST_SUCCESS:
            case POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY:
                draft.action.isSuccess = true;
                draft.action.actionName = POST_CONFIRM_HOME_TEST_SUCCESS;
                draft.action.isLoading = false;
                break;
            case POST_CONFIRM_HOME_TEST_FAILED:
            case POST_CONFIRM_HOME_TEST_FAILED_MANUALLY:
                draft.action.isSuccess = false;
                draft.action.error = action.error.data.error_code ? action.error.data.error_code : "Unknown error";
                draft.action.actionName = POST_CONFIRM_HOME_TEST_FAILED;
                draft.action.isLoading = false;
                break;
            case GET_CUSTOMER_ATTACHMENTS_FAILED:
            case GET_ANAMNESIS_FAILED:
            case POST_ANAMNESIS_FAILED:
                draft.action.isLoading = false;
                draft.action.error = action.payload ? action.payload : "Unknown error";
                draft.action.isSuccess = false;
                draft.action.actionName = action.type;
                break;
            case RESET_ACTION:
                draft.action = initialState.action;
                break;
            case SHOW_QRCODE_POP_UP:
                draft.showQrCodePopup = !state.showQrCodePopup;
                break;
            case CLEAR_TABLE_FILTER:
                draft.filteredData = state.data;
                break;
            case UPDATE_CONSULTATION_STATE_RECORD:
                draft.consultationsData = state.consultationsData.map((el) => {
                    if (el.uid === action.id) {
                        return { ...el, record_state: "T" };
                    }
                    return { ...el };
                });
                break;
            case GET_ANAMNESIS_SUCCESS:
                const anamnesisState = action.payload.map(el => el.anamnesis_questions.map(e => {
                    // if (e.answer === undefined) {
                    return { ...e }
                    // } else {
                    //     return { ...e, answer: null, answer_comment: "" }
                    // }
                })).flat();
                draft.anamnesisResult = action.payload;
                draft.anamnesisState = anamnesisState;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = GET_ANAMNESIS_SUCCESS;
                draft.action.message = "Anamnesis data fetched successfully";
                break;
            case POST_ANAMNESIS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = POST_ANAMNESIS_SUCCESS;
                draft.action.message = "Anamnesis was updated successfully.";
                break;
            case SET_ANAMNESIS_STATE:
                const updatedState = state.anamnesisState.map((el) => {
                    if (el.question_id === action.payload.question_id) {
                        return { ...el, answer_comment: action.payload.value, answer: action.payload.checkbox };
                    }
                    return { ...el };
                })
                draft.anamnesisState = [...updatedState];
                break;
            case GET_EXAMINATIONS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = GET_EXAMINATIONS_SUCCESS;
                draft.examinations = action.payload;
                break;
            case GET_ANAMNESIS_HISTORY_SUCCESS:
                draft.anamnesisHistory = action.payload;
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = GET_ANAMNESIS_HISTORY_SUCCESS;
                draft.action.message = "Anamnesis history fetched successfully";
                break;
            case GET_VITAL_SIGNS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = GET_VITAL_SIGNS_SUCCESS;
                draft.vitalSignsResult = action.payload;
                break;
            case GET_CUSTOMER_ATTACHMENTS_SUCCESS:
                draft.action.isLoading = false;
                draft.action.isSuccess = true;
                draft.action.actionName = GET_CUSTOMER_ATTACHMENTS_SUCCESS;
                break;
            default:
                break;
        }
    });

export default dashboardReducer;
