import api from "services/api";
import { getPhone, updatePhoneNumberAuth } from "actions/authentication/authentication";

export const MY_ACCOUNT_CHANGE_PASSWORD_BEGIN = "MY_ACCOUNT_CHANGE_PASSWORD_BEGIN";
export const MY_ACCOUNT_CHANGE_PASSWORD_SUCCESS = "MY_ACCOUNT_CHANGE_PASSWORD_SUCCESS";
export const MY_ACCOUNT_CHANGE_PASSWORD_FAILED = "MY_ACCOUNT_PASSWORD_FAILED";
export const MY_ACCOUNT_SET_PERSONAL_DETAILS_BEGIN = "MY_ACCOUNT_SET_PERSONAL_DETAILS_BEGIN";
export const MY_ACCOUNT_SET_PERSONAL_DETAILS_SUCCESS = "MY_ACCOUNT_SET_PERSONAL_DETAILS_SUCCESS";
export const MY_ACCOUNT_SET_PERSONAL_DETAILS_FAILED = "MY_ACCOUNT_SET_PERSONAL_DETAILS_FAILED";
export const MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_BEGIN = "MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_BEGIN";
export const MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_SUCCESS = "MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_SUCCESS";
export const MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_FAILED = "MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_FAILED";
export const MY_ACCOUNT_VERIFY_PHONE_BEGIN = "MY_ACCOUNT_VERIFY_PHONE_BEGIN";
export const MY_ACCOUNT_VERIFY_PHONE_SUCCESS = "MY_ACCOUNT_VERIFY_PHONE_SUCCESS";
export const MY_ACCOUNT_VERIFY_PHONE_FAILED = "MY_ACCOUNT_VERIFY_PHONE_FAILED";
export const MY_ACCOUNT_UPDATE_ADDRESS_BEGIN = "MY_ACCOUNT_UPDATE_ADDRESS_BEGIN";
export const MY_ACCOUNT_UPDATE_ADDRESS_SUCCESS = "MY_ACCOUNT_UPDATE_ADDRESS_SUCCESS";
export const MY_ACCOUNT_UPDATE_ADDRESS_FAILED = "MY_ACCOUNT_UPDATE_ADDRESS_FAILED";
export const UPDATE_NOTIFICATION_SETTINGS_BEGIN = "UPDATE_NOTIFICATION_SETTINGS_BEGIN";
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = "UPDATE_NOTIFICATION_SETTINGS_SUCCESS";
export const UPDATE_NOTIFICATION_SETTINGS_FAILED = "UPDATE_NOTIFICATION_SETTINGS_FAILED";
export const MY_ACCOUNT_CLEAR_ACTION = "MY_ACCOUNT_CLEAR_ACTION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS_BY_CONVERSATION_ID = "DELETE_NOTIFICATION_BY_CONVERSATION_ID";
export const ADD_NOTIFICATION_ITEM = "ADD_NOTIFICATION_ITEM";

export const setNotifications = (data) => ({
    type: SET_NOTIFICATIONS,
    payload: data
});

export const deleteNotificaionsByConversationId = (conversationId: string) => ({
    type: DELETE_NOTIFICATIONS_BY_CONVERSATION_ID,
    payload: conversationId
});

export const addNotificaionItem = (payload) => ({
    type: ADD_NOTIFICATION_ITEM,
    payload: payload
});

export const changePasswordBegin = () => ({
    type: MY_ACCOUNT_CHANGE_PASSWORD_BEGIN
});

export const changePasswordSuccess = (data) => ({
    type: MY_ACCOUNT_CHANGE_PASSWORD_SUCCESS,
    payload: data
});
export const changePasswordFailed = (error) => ({
    type: MY_ACCOUNT_CHANGE_PASSWORD_FAILED,
    payload: error
});

export const setPersonalDetailsBegin = () => ({
    type: MY_ACCOUNT_SET_PERSONAL_DETAILS_BEGIN
});

export const setPersonalDetailsSuccess = (data) => ({
    type: MY_ACCOUNT_SET_PERSONAL_DETAILS_SUCCESS,
    payload: data
});

export const setPersonalDetailsFailed = (error) => ({
    type: MY_ACCOUNT_SET_PERSONAL_DETAILS_FAILED,
    payload: error
});

export const getNotificationSettingsBegin = () => ({
    type: MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_BEGIN
});

export const getNotificationSettingsSuccess = (data) => ({
    type: MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_SUCCESS,
    payload: data
});

export const getNotificationSettingsFailed = (error) => ({
    type: MY_ACCOUNT_GET_NOTIFICATION_SETTINGS_FAILED,
    payload: error
});

export const verifyPhoneBegin = () => ({
    type: MY_ACCOUNT_VERIFY_PHONE_BEGIN
});

export const verifyPhoneSuccess = (response) => ({
    type: MY_ACCOUNT_VERIFY_PHONE_SUCCESS,
    payload: response
});

export const verifyPhoneFailed = (error) => ({
    type: MY_ACCOUNT_VERIFY_PHONE_FAILED,
    payload: error
});

export const updateAddressBegin = () => ({
    type: MY_ACCOUNT_UPDATE_ADDRESS_BEGIN
});

export const updateAddressSuccess = (data) => ({
    type: MY_ACCOUNT_UPDATE_ADDRESS_SUCCESS,
    payload: data
});

export const updateAddressFail = (error) => ({
    type: MY_ACCOUNT_UPDATE_ADDRESS_FAILED,
    payload: error
});

export const updateNotificationSettingsBegin = () => ({
    type: UPDATE_NOTIFICATION_SETTINGS_BEGIN
});

export const updateNotificationSettingsSuccess = (data) => ({
    type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
    payload: data
});
export const updateNotificationSettingsFailed = (error) => ({
    type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
    payload: error
});

export const clearAction = () => ({
    type: MY_ACCOUNT_CLEAR_ACTION
});

export const changePassword = (data: any) => {
    return async (dispatch: any) => {
        try {
            // await dispatch(authorize());
            // dispatch(changePasswordBegin());
            const response = await api.put("/ident/self/password", {
                current_password: data.current_password,
                password: data.password
            });
            return dispatch(changePasswordSuccess(response.data));
        } catch (error) {
            return dispatch(changePasswordFailed(error?.response?.data));
        }
    };
};

export const setPersonalDetails = (data: any) => {
    return async (dispatch: any) => {
        try {
            //   dispatch(setPersonalDetailsBegin());
            const response = await api.put("/ident/self/person", data);
            //   dispatch(setPersonalDetailsSuccess(response.data));
            return dispatch(setPersonalDetailsSuccess(response.data));
        } catch (error) {
            //   dispatch(setPersonalDetailsFailed(error.response.data));
            return "error";
        }
    };
};

export const createAdditionalInfo = (data: any) => {
    return async (dispatch: any) => {
        try {
            const response = await api.post("/ident/self/details", data);
            return dispatch(setPersonalDetailsSuccess(response.data));
        } catch (error) {
            return dispatch(setPersonalDetailsFailed(error.response.statusText || 'Error'));
        }
    };
};

export const updateAdditionalInfo = (additionalInfo: any) => {
    return async (dispatch: any) => {
        try {
            const response = await api.put(`/ident/self/details`, additionalInfo);
            return dispatch(setPersonalDetailsSuccess(response.data));
        } catch (error) {
            return dispatch(setPersonalDetailsFailed(error.response.statusText || 'Error'));
        }
    };
};

export const getNotificationSettings = (account_number, person_id) => {
    return async (dispatch: any) => {
        try {
            // dispatch(getNotificationSettingsBegin());
            const response = await api.get(
                `/core/account/notification?account_number=${account_number}&person_id=${person_id}`
            );
            return dispatch(getNotificationSettingsSuccess(response.data));
        } catch (error) {
            return dispatch(getNotificationSettingsFailed(error.response.data));
        }
    };
};

export const updatePhoneNumber = (phone_number: string, delivery?: string) => {
    // A is for Address, P is for Profile
    return async (dispatch: any) => {
        dispatch(verifyPhoneBegin());
        try {
            const response = await api.post("/ident/self/phone", {
                phone_number: phone_number,
                phone_number_class: delivery
            });
            if (response) {
                const phoneData = await dispatch(getPhone());
                dispatch(updatePhoneNumberAuth(phoneData.data));
            }
        } catch (error) {
            return dispatch(verifyPhoneFailed(error.response.data));
        }
    };
};

export const updateAddress = (address_id: string, address: any) => {
    return async (dispatch: any) => {
        try {
            // dispatch(updateAddressBegin());
            const response = await api.put(`/ident/self/address/${address_id}`, address);
            dispatch(updateAddressSuccess(response.data));
        } catch (error) {
            dispatch(updateAddressFail(error.response.data));
        }
    };
};

export const updateNotificationSettings = (account_number: string, person_id: number, settings: any) => {
    return async (dispatch: any) => {
        try {
            // await dispatch(authorize());
            // dispatch(updateNotificationSettingsBegin());
            const response = await api.put(
                `/core/account/notification?account_number=${account_number}&person_id=${person_id}`,
                settings
            );
            return dispatch(updateNotificationSettingsSuccess(response.data));
        } catch (error) {
            return dispatch(updateNotificationSettingsFailed(error?.response?.data));
        }
    };
};
