// import { getUploadedDocuments } from "actions/uploadDocuments/uploadDocuments";
import { generateQueryParams } from "helpers";
import api from "services/api";
export const GET_PRODUCT_ORDERS_BEGIN = "GET_PRODUCT_ORDERS_BEGIN";
export const GET_PRODUCT_ORDERS_SUCCESS = "GET_PRODUCT_ORDERS_SUCCESS";
export const GET_PRODUCT_ORDERS_FAILED = "GET_PRODUCT_ORDERS_FAILED";

export const POST_CONFIRM_HOME_TEST_BEGIN = "POST_CONFIRM_HOME_TEST_BEGIN";
export const POST_CONFIRM_HOME_TEST_SUCCESS = "POST_CONFIRM_HOME_TEST_SUCCESS";
export const POST_CONFIRM_HOME_TEST_FAILED = "POST_CONFIRM_HOME_TEST_FAILED";

export const POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY = "POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY";
export const POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY = "POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY";
export const POST_CONFIRM_HOME_TEST_FAILED_MANUALLY = "POST_CONFIRM_HOME_TEST_FAILED_MANUALLY";

export const RESET_ACTION = "RESET_ACTION";
export const SHOW_QRCODE_POP_UP = "SHOW_QRCODE_POP_UP";

export const UPDATE_CONSULTATION_STATE_RECORD = "UPDATE_CONSULTATION_STATE_RECORD";
export const HANDLE_BARCODE = "HANDLE_BARCODE";

export const GET_PRODUCT_BARCODE_BEGIN = "GET_PRODUCT_BARCODE_BEGIN";
export const GET_PRODUCT_BARCODE_SUCCESS = "GET_PRODUCT_BARCODE_SUCCESS";
export const GET_PRODUCT_BARCODE_FAILED = "GET_PRODUCT_BARCODE_FAILED";

export const GET_SICK_LEAVE_BEGIN = "GET_SICK_LEAVE_BEGIN";
export const GET_SICK_LEAVE_SUCCESS = "GET_SICK_LEAVE_SUCCESS";
export const GET_SICK_LEAVE_FAILED = "GET_SICK_LEAVE_FAILED";

export const GET_PRESCRIPTION_BEGIN = "GET_PRESCRIPTION_BEGIN";
export const GET_PRESCRIPTION_SUCCESS = "GET_PRESCRIPTION_SUCCESS";
export const GET_PRESCRIPTION_FAILED = "GET_PRESCRIPTION_FAILED";

export const GET_ANAMNESIS_BEGIN = "GET_ANAMNESIS_BEGIN";
export const GET_ANAMNESIS_SUCCESS = "GET_ANAMNESIS_SUCCESS";
export const GET_ANAMNESIS_FAILED = "GET_ANAMNESIS_FAILED";
export const GET_ANAMNESIS_HISTORY_SUCCESS = "GET_ANAMNESIS_HISTORY_SUCCESS";

export const POST_ANAMNESIS_BEGIN = "POST_ANAMNESIS_BEGIN";
export const POST_ANAMNESIS_SUCCESS = "POST_ANAMNESIS_SUCCESS";
export const POST_ANAMNESIS_FAILED = "POST_ANAMNESIS_FAILED";

export const SET_ANAMNESIS_STATE = "SET_ANAMNESIS_STATE"

export const GET_EXAMINATIONS_BEGIN = "GET_EXAMINATIONS_BEGIN";
export const GET_EXAMINATIONS_SUCCESS = "GET_EXAMINATIONS_SUCCESS";
export const GET_EXAMINATIONS_FAILED = "GET_EXAMINATIONS_FAILED";

export const GET_VITAL_SIGNS_BEGIN = "GET_VITAL_SIGNS_BEGIN";
export const GET_VITAL_SIGNS_SUCCESS = "GET_VITAL_SIGNS_SUCCESS";
export const GET_VITAL_SIGNS_FAILED = "GET_VITAL_SIGNS_FAILED";

export const GET_CUSTOMER_ATTACHMENTS_BEGIN = "GET_CUSTOMER_ATTACHMENTS_BEGIN";
export const GET_CUSTOMER_ATTACHMENTS_SUCCESS = "GET_CUSTOMER_ATTACHMENTS_SUCCESS";
export const GET_CUSTOMER_ATTACHMENTS_FAILED = "GET_CUSTOMER_ATTACHMENTS_FAILED";

const getProductOrdersBegin = () => ({
    type: GET_PRODUCT_ORDERS_BEGIN
});

export const resetAction = () => ({
    type: RESET_ACTION
});

const getProductOrdersSuccess = (data) => ({
    type: GET_PRODUCT_ORDERS_SUCCESS,
    payload: data
});

const getSickLeaveBegin = () => ({
    type: GET_SICK_LEAVE_BEGIN
});

const getSickLeaveSuccess = (data) => ({
    type: GET_SICK_LEAVE_SUCCESS,
    payload: data
});

const getSickLeaveFailed = (error) => ({
    type: GET_SICK_LEAVE_FAILED,
    error: error
});

const getPrescriptionBegin = () => ({
    type: GET_PRESCRIPTION_BEGIN
});

const getPrescriptionSuccess = (data) => ({
    type: GET_PRESCRIPTION_SUCCESS,
    payload: data
});

const getPrescriptionFailed = (error) => ({
    type: GET_PRESCRIPTION_FAILED,
    error: error
});

const getProductOrdersFailed = (error) => ({
    type: GET_PRODUCT_ORDERS_FAILED,
    error: error
});

export const handleBarcode = (key: string) => ({
    type: HANDLE_BARCODE,
    payload: key
});

export const showQrCodePopup = () => ({
    type: SHOW_QRCODE_POP_UP
});

export const updateConsultationStateRecord = (id: string) => ({
    type: UPDATE_CONSULTATION_STATE_RECORD,
    id: id
});

export const getProductOrders = (accountNumber: string, dateFrom: string, dateTo: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getProductOrdersBegin());
            const response = await api.get(`/core/account/${accountNumber}/product_orders`, {
                params: {
                    date_from: dateFrom,
                    date_to: dateTo
                }
            });
            dispatch(getProductOrdersSuccess(response.data || []));
            return response;
        } catch (error) {
            dispatch(getProductOrdersFailed(error.response));
        }
    };
};

export const getSickLeave = (accountNumber: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getSickLeaveBegin());
            const response = await api.get(`/core/account/${accountNumber}/sick_leave`);
            dispatch(getSickLeaveSuccess(response.data || []));
            return response;
        } catch (error) {
            dispatch(getSickLeaveFailed(error))
        }
    };
};

export const getPrescription = (accountNumber: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getPrescriptionBegin());
            const response = await api.get(`/core/account/${accountNumber}/prescription`);
            dispatch(getPrescriptionSuccess(response.data || []));
            return response;
        } catch (error) {
            dispatch(getPrescriptionFailed(error))
        }
    };
};

const postConfirmHomeTestBegin = () => ({
    type: POST_CONFIRM_HOME_TEST_BEGIN
});

const postConfirmHomeTestSuccess = (data) => ({
    type: POST_CONFIRM_HOME_TEST_SUCCESS,
    payload: data
});

const postConfirmHomeTestFailed = (error) => ({
    type: POST_CONFIRM_HOME_TEST_FAILED,
    error: error
});

const postConfirmHomeTestBeginManually = () => ({
    type: POST_CONFIRM_HOME_TEST_BEGIN_MANUALLY
});

const postConfirmHomeTestSuccessManually = (data) => ({
    type: POST_CONFIRM_HOME_TEST_SUCCESS_MANUALLY,
    payload: data
});

const postConfirmHomeTestFailedManually = (error) => ({
    type: POST_CONFIRM_HOME_TEST_FAILED_MANUALLY,
    error: error
});

export const postConfirmHomeTest = (
    ta_id: number,
    product_order_id: number,
    product_order_item_id: number,
    test_id: string
) => {
    return async (dispatch: any) => {
        try {
            dispatch(postConfirmHomeTestBegin());
            const response = await api.post(
                `/core/person/confirm_home_test?ta_id=${ta_id}&product_order_id=${product_order_id}&product_order_item_id=${product_order_item_id}&test_id=${test_id}`
            );
            dispatch(postConfirmHomeTestSuccess(response.data));
            return response;
        } catch (error) {
            dispatch(postConfirmHomeTestFailed(error.response));
        }
    };
};

export const postConfirmHomeTestManually = (barcode: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(postConfirmHomeTestBeginManually());
            const response = await api.post(`/core/payments/product_order_activate`, {
                barcode: barcode
            });
            return dispatch(postConfirmHomeTestSuccessManually(response.data));
        } catch (error) {
            dispatch(postConfirmHomeTestFailedManually(error.response));
        }
    };
};

export const getProductBarcode = (ta_id: number, product_order_id: number, product_order_item_id: number) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: GET_PRODUCT_BARCODE_BEGIN
            });
            const response = await api.get(
                `/core/product_order_barcode?ta_id=${ta_id}&product_order_id=${product_order_id}&product_order_item_id=${product_order_item_id}`
            );
            dispatch({
                type: GET_PRODUCT_BARCODE_SUCCESS,
                payload: response.data
            });
            return response;
        } catch (error) {
            dispatch({
                type: GET_PRODUCT_BARCODE_FAILED,
                payload: error.response.data
            });
        }
    };
};

export const DASHBOARD_SEARCH_DATA = "DASHBOARD_SEARCH_DATA";
export const dashboardSearchData = (keyword: string) => {
    return {
        type: DASHBOARD_SEARCH_DATA,
        keyword
    };
};

export const SET_SEARCH_KEYS = "SET_SEARCH_KEYS";
export const setSearchKeys = (keys: Array<string>) => {
    return {
        type: SET_SEARCH_KEYS,
        payload: keys
    };
};

export const SET_DATA_TO_RENDER = "SET_DATA_TO_RENDER";
export const setDataToRender = (dataToRender: any) => {
    return {
        type: SET_DATA_TO_RENDER,
        payload: dataToRender
    };
};

export const SET_COLUMN_PER_PAGE = "SET_COLUMN_PER_PAGE";
export const setColumnPerPage = (value: number) => {
    return {
        type: SET_COLUMN_PER_PAGE,
        payload: value
    };
};

export const SET_ORDER = "SET_ORDER";
export const setOrder = (value: string) => {
    return {
        type: SET_ORDER,
        payload: value
    };
};

export const SET_STATUS = "SET_STATUS";
export const setStatus = (value: string) => {
    return {
        type: SET_STATUS,
        payload: value
    };
};

export const CLEAR_TABLE_FILTER = "CLEAR_TABLE_FILTER";
export const clearTableFilter = () => {
    return {
        type: CLEAR_TABLE_FILTER
    };
};

const getAnamnesisResultBegin = () => ({
    type: GET_ANAMNESIS_BEGIN
});

const getAnamnesisResultSuccess = (data) => ({
    type: GET_ANAMNESIS_SUCCESS,
    payload: data
});

const getAnamnesisResultFailed = (error) => ({
    type: GET_ANAMNESIS_FAILED,
    error: error
});

const getAnamnesisHistorySuccess = (data) => ({
    type: GET_ANAMNESIS_HISTORY_SUCCESS,
    payload: data
});

// export const getAnamnesisResult = (personId: string, lang: string, ta_id?: number, product_order_id?: number, product_order_item_id?: number, show_history?: boolean) => {
//     return async (dispatch: any) => {
//         try {
//             let queryParams = generateQueryParams(ta_id ? {
//                 language: lang,
//                 ta_id: ta_id,
//                 product_order_id: product_order_id,
//                 product_order_item_id: product_order_item_id,
//                 show_history: show_history
//             } : {
//                 language: lang,
//             })
//             dispatch(getAnamnesisResultBegin());
//             const response = await api.get(`/core/person/${personId}/anamnesis${queryParams}`);
//             console.log("yyy", response)
//             show_history ? dispatch(getAnamnesisHistorySuccess(response.data))
//                 :
//                 dispatch(getAnamnesisResultSuccess(response.data));
//             return response;


//         } catch (error) {
//             dispatch(getAnamnesisResultFailed(error))
//         }
//     };
// };
export const getAnamnesisResult = (personId: string, lang: string, ta_id?: number, product_order_id?: number, product_order_item_id?: number, show_history?: boolean) => {
    return async (dispatch: any) => {
        try {
            let queryParams = generateQueryParams(ta_id ? {
                language: lang,
                ta_id: ta_id,
                product_order_id: product_order_id,
                product_order_item_id: product_order_item_id,
                show_history: show_history
            } : {
                language: lang,
                show_history: show_history
            });
            dispatch(getAnamnesisResultBegin());

            const response = await api.get(`/core/person/${personId}/anamnesis${queryParams}`);
            if (show_history) {
                dispatch(getAnamnesisHistorySuccess(response.data));
            } else {
                dispatch(getAnamnesisResultSuccess(response.data));
            }
            return response;
        } catch (error) {
            console.error("Error fetching anamnesis result:", error);
            dispatch(getAnamnesisResultFailed(error));
        }
    };
};



const postAnamnesisResultBegin = () => ({
    type: POST_ANAMNESIS_BEGIN
});

const postAnamnesisResultSuccess = (data) => ({
    type: POST_ANAMNESIS_SUCCESS,
    payload: data
});

const postAnamnesisResultFailed = (error) => ({
    type: POST_ANAMNESIS_FAILED,
    error: error
});

export const postAnamnesisResult = (state: {
    "ta_id"?: number,
    "product_order_id"?: number,
    "product_order_item_id"?: number,
    "answers": [
        {
            "anamnesis_question_id": number,
            "anamnesis_answer": boolean,
            "answer_comment": string
        }
    ]
}) => {
    return async (dispatch: any) => {
        try {
            dispatch(postAnamnesisResultBegin());
            const response = await api.post(`/core/person/anamnesis/answers`, state);
            dispatch(postAnamnesisResultSuccess(response.data || []));
            return response;
        } catch (error) {
            dispatch(postAnamnesisResultFailed(error))
        }
    };
};

export const setAnamnasisState = (obj) => ({
    type: SET_ANAMNESIS_STATE,
    payload: obj
});

const getExaminationsBegin = () => ({
    type: GET_EXAMINATIONS_BEGIN
});

const getExaminationsSuccess = (data) => ({
    type: GET_EXAMINATIONS_SUCCESS,
    payload: data
});

const getExaminationsFailed = (error) => ({
    type: GET_EXAMINATIONS_FAILED,
    error: error
});

export const getExaminations = (accountNumber: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getExaminationsBegin());
            const response = await api.get(`/core/account/${accountNumber}/product_order_examination_list`);
            dispatch(getExaminationsSuccess(response.data || []));
            return response;
        } catch (error) {
            dispatch(getExaminationsFailed(error))
        }
    };
};

const getVitalSignsBegin = () => ({
    type: GET_VITAL_SIGNS_BEGIN
});

const getVitalSignsSuccess = (data) => ({
    type: GET_VITAL_SIGNS_SUCCESS,
    payload: data
});

const getVitalSignsFailed = (error) => ({
    type: GET_VITAL_SIGNS_FAILED,
    error: error
});

export const getVitalSigns = (accountNumber: string, vital_sign_group_id_out?: number) => {
    return async (dispatch: any) => {
        try {
            dispatch(getVitalSignsBegin());
            const response = await api.get(`/core/account/${accountNumber}/vital_sign${vital_sign_group_id_out ? `?vital_sign_group_id=${vital_sign_group_id_out}` : ''
                }`);
            dispatch(getVitalSignsSuccess(response.data || []));
            return response;
        } catch (error) {
            dispatch(getVitalSignsFailed(error))
        }
    };
};

const getCustomerAttachmentsBegin = () => ({ type: GET_CUSTOMER_ATTACHMENTS_BEGIN });
const getCustomerAttachmentsSuccess = (data) => ({ type: GET_CUSTOMER_ATTACHMENTS_SUCCESS, payload: data });
const getCustomerAttachmentsFailed = (error) => ({ type: GET_CUSTOMER_ATTACHMENTS_FAILED, error: error });

type CustomerAttachmentParams = {
    date_from: string,
    date_to: string,
    data_group_id?: number,
}

export const getCustomerAttachments = (params: CustomerAttachmentParams, document_type: string) => {
    return async (dispatch: any) => {
        try {
            dispatch(getCustomerAttachmentsBegin());
            const response = await api.get(`/core/person/attachment/${document_type}`, {
                params: params,
                responseType: "blob",
            });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // Extract filename from headers if available, or use a default
            const contentDisposition = response.headers["content-disposition"];
            const fileName = contentDisposition
                ? contentDisposition.split("filename=")[1]?.replace(/"/g, "") // Extract filename
                : "health-report.pdf"; // Default filename if none provided

            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            dispatch(getCustomerAttachmentsSuccess(response.data));
            return response
        } catch (error) {
            console.error("Error downloading the file:", error);
            dispatch(getCustomerAttachmentsFailed(error));
            return error;
        }
    };
}
