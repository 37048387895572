import LoaderBounce from "components/loader/bounce/LoaderBounce";
import { memo, useCallback, useEffect } from "react";
interface Props extends React.HTMLProps<HTMLButtonElement> {
    type?: "button" | "submit" | "reset";
    label: string;
    class?: string;
    isLoading?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    allowEnter?: boolean;
    iconClass?: string;
}
const Button: React.FC<Props> = ({ label, isLoading, iconClass, ...props }) => {
    const handleEnterKeyPress = useCallback(
        (event) => {
            if (event.keyCode === 13) {
                props.allowEnter && props.handleClick(event);
            }
        },
        [props.handleClick]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleEnterKeyPress);
        return () => {
            document.removeEventListener("keydown", handleEnterKeyPress);
        };
    }, [handleEnterKeyPress]);

    return (
        <button
            className={`btn ${props.class} ${isLoading ? "loading" : null}`}
            onClick={props.handleClick}
            disabled={props.disabled}
            style={props.style}
            type={props.type}
            {...props}
        >
            {iconClass && !isLoading ? <i className={iconClass} style={{ paddingRight: 5 }}></i> : null}
            {isLoading ? (
                <span className={"btn-loader"}>
                    <LoaderBounce />
                </span>
            ) : (
                label
            )}
        </button>
    );
};
export default memo(Button);
