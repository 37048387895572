import { ReactNode } from "react";

export interface ModalIState {
    openModal?: boolean;
    closeModal?: boolean;
    modalContent?: ReactNode;
    modalStyle?: React.CSSProperties;
    type?: string;
    hideModalHeader?: boolean;
}

export enum modalTypes {
    sm = "sm",
    md = "md",
    xl = "xl"
}

export enum myAccountModals {
    UPDATE_PHONE_NUMBER_MODAL = "UPDATE_PHONE_NUMBER_MODAL",
    UPDATE_BIRTHDATE_MODAL = "UPDATE_BIRTHDATE_MODAL",
    UPDATE_GENDER_MODAL = "UPDATE_GENDER_MODAL",
    UPDATE_ADDRESS_MODAL = "UPDATE_ADDRESS_MODAL",
    UPDATE_APP_LANGUAGE_MODAL = "UPDATE_APP_LANGUAGE_MODAL",
    CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL",
    DELETE_ACCOUNT_MODAL = "DELETE_ACCOUNT_MODAL",
    UPDATE_HEIGHT_MODAL = "height",
    UPDATE_WEIGHT_MODAL = "weight",
    UPDATE_BLOOD_TYPE_MODAL = "blood_type",
    UPDATE_RELATION_STATUS_MODAL = "relation_status",
}
